<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.accounts.edit_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <form @submit.prevent="updateAccounts" autocomplete="off">
                <v-layout row wrap>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="accounts.account_name" type="text" :label="$store.getters.language.data.accounts.account_name" dense
                            class="mx-1"  filled outlined required >
                        </v-text-field>
                    </v-flex>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="accounts.account_phone" type="text" :label="$store.getters.language.data.accounts.account_phone" dense
                            class="mx-1" filled outlined>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 lg3 xl3 md3 sm4>
                        <v-select class="mx-1" clearable :items="[{text: $store.getters.language.data.accounts.store, value: 'store'}, {text: $store.getters.language.data.accounts.out_store, value: 'out_store'}]" v-model="accounts.account_type" dense  filled outlined item-text="text"
                            item-value="value" :return-object="false" :label="$store.getters.language.data.accounts.account_type">
                        </v-select>
                    </v-flex>
            
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-select class="mx-1" clearable  :items="[{text: $store.getters.language.data.yes, value: 'true'}, {text: $store.getters.language.data.no, value: 'false'}]" v-model="accounts.account_is_default" dense  filled outlined item-text="text"
                            item-value="value" :return-object="false" :label="$store.getters.language.data.accounts.account_is_default">
                        </v-select>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn  color="success" type="submit" :disabled="buttonDisable">{{$store.getters.language.data.accounts.update_btn}}</v-btn>
                    </v-flex>
                </v-layout>
            </form>
        </v-container>
    <v-snackbar v-model="snackbar.value" :color="snackbar.color">
        {{snackbar.text}}
    </v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/accounts.request.js'
    export default {
        data() {
            return {
                accounts: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
            }
        },
        computed: {
            buttonDisable() {
                return !this.accounts.account_name ||
                    !this.accounts.account_type ||
                    !this.accounts.account_is_default
            }
        },
        mounted(){
            this.id = this.$route.params.id
            if(this.$store.state.store_initialized) {
                this.getOneAccounts()
            }
        },
        methods: {
            getOneAccounts() {
                this.loading = true
                requests.getOneAccounts(this.id).then(r =>{
                    if (r.status == 200) {
                        this.accounts = r.data.row
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.failed,
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            updateAccounts() {
                this.loading = true
                const defaults = this.$store.getters.accounts_list.filter(obj => obj.account_is_default == 'true');
                if(defaults.length == 1 && this.accounts.account_id != defaults[0].account_id && this.accounts.account_is_default == 'true') {
                    this.snackbar = {
                        value: true,
                        text: this.$store.getters.language.data.messages.one_default_account,
                        color: 'info'
                    }
                    this.loading = false
                } else {
                    requests.updateAccounts(this.id,this.accounts).then(r => {
                        if (r.status == 200) {
                            this.snackbar = {
                                value: true,
                                text: this.$store.getters.language.data.messages.update_success,
                                color: 'success'
                            }
                            let index = this.$store.getters.accounts_list.findIndex(obj => obj.account_id == this.id);
                            this.$store.state.accounts_list[index] = r.data.new_data
                            this.loading = false
                            this.$router.push('/accounts-list');
                        } else {
                            this.snackbar = {
                                value: true,
                                text: this.$store.getters.language.data.messages.failed,
                                color: 'error'
                            }
                            this.loading = false
                        }
                    })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: this.$store.getters.language.data.messages.failed,
                            color: 'error'
                        }
                        this.loading = false
                    })
                    .finally(() => {
                        this.loading = false
                    })
                }
            },
        },
    }
</script>
                    